import React from "react";
import PropTypes from "prop-types";

export default function Image(props) {
  const alt = props.alt;
  const src = {
    fallback: props.src,
    xs: props.srcXS ? props.srcXS : null,
    sm: props.srcSM ? props.srcSM : null,
    md: props.srcMD ? props.srcMD : null,
    lg: props.srcLG ? props.srcLG : null,
    xl: props.srcXL ? props.srcXL : null,
    hd: props.srcHD ? props.srcHD : null,
  };

  let srcSet = src.fallback;

  if (src.hd || src.xl || src.lg || src.md || src.sm || src.xs) {
    srcSet =
      (src.hd ? src.hd + " 1920w," : "") +
      (src.xl ? src.xl + " 1200w," : "") +
      (src.lg ? src.lg + " 992w," : "") +
      (src.md ? src.md + " 768w," : "") +
      (src.sm ? src.sm + " 544w," : "") +
      (src.xs ? src.xs + " 320w" : "");
  }

  return (
    <picture className={props.className}>
      {srcSet === src.fallback ? (
        <source srcSet={src.fallback} />
      ) : (
        <source srcSet={srcSet} sizes="10 0vw" />
      )}
      <img alt={alt} src={src.fallback} />
    </picture>
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,

  src: PropTypes.string.isRequired,
  srcXS: PropTypes.string,
  srcSM: PropTypes.string,
  srcMD: PropTypes.string,
  srcLG: PropTypes.string,
  srcXL: PropTypes.string,
  srcHD: PropTypes.string,
};
