import React from 'react';
import Image from './Image';

export default function VideoLink(props) {
  return (
    <a href={props.href} target='_blank' rel='noopener noreferrer' className='video-crossfade'>
      <div className='slides'>
        <Image src={'media/videos/16x9.png'} alt='' />
        <div className='slide'>
          <Image src={`./media/videos/${props.src}/0.jpg`} alt={`${props.src}`} />
        </div>
        <div className='slide'>
          <Image src={`./media/videos/${props.src}/1.jpg`} alt={`${props.src}`} />
        </div>
        <div className='slide'>
          <Image src={`./media/videos/${props.src}/2.jpg`} alt={`${props.src}`} />
        </div>
        <div className='slide'>
          <Image src={`./media/videos/${props.src}/3.jpg`} alt={`${props.src}`} />
        </div>
        <div className='slide'>
          <Image src={`./media/videos/${props.src}/4.jpg`} alt={`${props.src}`} />
        </div>
        <p>
          <i>
            <svg xmlns='http://www.w3.org/2000/svg' width='72' height='72' viewBox='0 0 24 24'>
              <path d='M3 22v-20l18 10-18 10z' />
            </svg>
          </i>
        </p>
      </div>
    </a>
  );
}
