import { Row, Col, Line } from './Layout/Layout';
import Paragraph from './Paragraph';
// import ParallaxInject from "./ParallaxInject";
// import Inject from "./Inject";
// import { isMobile } from "react-device-detect";

function Quote(props) {
  const text = props.quote.text;
  const name = props.quote.imageName || props.quote.name.split(' ')[0];

  return (
    <Row bg='brush-responsive' paddingTop='small' paddingBottom='small'>
      <Col double>
        <button className='portrait' onClick={() => window.open(props.quote.link, '_blank')}>
          <div className='shadow'></div>
          <div className='back cw'></div>
          <div className='back ccw'></div>
          <img
            className={`face ${name} layer-0`}
            src={`media/people/${name}-0.jpg`}
            alt={`${name} layer 0`}
          />
          <img
            className={`face ${name} layer-1`}
            src={`media/people/${name}-1.jpg`}
            alt={`${name} layer 1`}
          />
          <img
            className={`face ${name} layer-2`}
            src={`media/people/${name}-2.jpg`}
            alt={`${name} layer 2`}
          />
          <img
            className={`face ${name} layer-3`}
            src={`media/people/${name}-3.jpg`}
            alt={`${name} layer 3`}
          />
          <div className='linkedin'>
            <i className='im im-linkedin'></i>
          </div>
        </button>
      </Col>
      <Col triple>
        <p className='big margin-top-large font-sans'>
          <em>
            <span className='big font-sans'>{text}</span>

            {/* {isMobile ? (
              text
            ) : (
              <ParallaxInject range={{ values: [0, 1], interval: [0, 0.5] }}>
                <Inject text={text} />
              </ParallaxInject>
            )} */}
          </em>
        </p>
        <Paragraph marginTop='medium'>{props.quote.name}</Paragraph>

        <Paragraph type='caption' marginBottom='large'>
          {props.quote.job.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Paragraph>
      </Col>
      <Col>
        <Line marginTop='medium' marginBottom='medium'>
          <p className={`quotes ${props.aqua ? 'aqua' : ''}`}>''</p>
        </Line>
      </Col>
    </Row>
  );
}

export default Quote;
