import { useEffect, useState, useRef } from "react";

let layers = [
  { name: "1", x: 447, y: 507, w: 36, h: 19 },
  { name: "2", x: 459, y: 513, w: 80, h: 54 },
  { name: "3", x: 630, y: 577, w: 12, h: 81 },
  { name: "4", x: 448, y: 432, w: 193, h: 486 },
  { name: "5", x: 108, y: 567, w: 268, h: 294 },
  { name: "6", x: 443, y: 632, w: 53, h: 23 },
  { name: "7", x: 467, y: 591, w: 151, h: 71 },
  { name: "8", x: 448, y: 504, w: 68, h: 10 },
  { name: "9", x: 643, y: 625, w: 50, h: 287 },
  { name: "10", x: 298, y: 74, w: 428, h: 342 },
  { name: "11", x: 432, y: 303, w: 38, h: 15 },
  { name: "12", x: 550, y: 322, w: 44, h: 17 },
  { name: "13", x: 542, y: 335, w: 12, h: 23 },
  { name: "14", x: 612, y: 321, w: 17, h: 21 },
  { name: "15", x: 684, y: 758, w: 188, h: 160 },
  { name: "16", x: 508, y: 769, w: 225, h: 186 },
  { name: "17", x: 457, y: 639, w: 171, h: 232 },
  { name: "18", x: 526, y: 100, w: 93, h: 44 },
  { name: "19", x: 311, y: 158, w: 382, h: 701 },
  { name: "20", x: 529, y: 226, w: 132, h: 152 },
  { name: "21", x: 556, y: 344, w: 20, h: 19 },
  { name: "22", x: 629, y: 173, w: 58, h: 121 },
  { name: "23", x: 532, y: 384, w: 23, h: 43 },
  { name: "24", x: 399, y: 338, w: 27, h: 16 },
  { name: "25", x: 368, y: 263, w: 134, h: 145 },
  { name: "26", x: 361, y: 802, w: 95, h: 117 },
  { name: "27", x: 368, y: 726, w: 98, h: 157 },
  { name: "28", x: 490, y: 790, w: 149, h: 200 },
  { name: "29", x: 530, y: 692, w: 95, h: 139 },
  { name: "30", x: 635, y: 616, w: 45, h: 165 },
  { name: "31", x: 653, y: 809, w: 33, h: 85 },
  { name: "32", x: 291, y: 365, w: 41, h: 113 },
  { name: "33", x: 314, y: 73, w: 454, h: 734 },
  { name: "34", x: 646, y: 250, w: 62, h: 322 },
  { name: "35", x: 429, y: 70, w: 274, h: 183 },
  { name: "36", x: 360, y: 140, w: 59, h: 61 },
  { name: "37", x: 284, y: 197, w: 76, h: 328 },
  { name: "38", x: 309, y: 350, w: 22, h: 119 },
  { name: "39", x: 496, y: 505, w: 81, h: 12 },
  { name: "40", x: 395, y: 314, w: 79, h: 28 },
  { name: "41", x: 393, y: 302, w: 76, h: 22 },
  { name: "42", x: 395, y: 265, w: 77, h: 27 },
  { name: "43", x: 380, y: 271, w: 14, h: 29 },
  { name: "44", x: 369, y: 325, w: 26, h: 13 },
  { name: "45", x: 408, y: 348, w: 40, h: 10 },
  { name: "46", x: 549, y: 323, w: 79, h: 27 },
  { name: "47", x: 543, y: 270, w: 90, h: 28 },
  { name: "48", x: 450, y: 578, w: 130, h: 73 },
  { name: "49", x: 359, y: 513, w: 69, h: 107 },
  { name: "50", x: 364, y: 550, w: 93, h: 100 },
  { name: "51", x: 419, y: 457, w: 80, h: 74 },
  { name: "52", x: 497, y: 491, w: 24, h: 7 },
  { name: "53", x: 470, y: 423, w: 30, h: 24 },
  { name: "54", x: 544, y: 398, w: 10, h: 25 },
  { name: "55", x: 616, y: 512, w: 18, h: 38 },
  { name: "56", x: 583, y: 490, w: 26, h: 62 },
  { name: "57", x: 561, y: 355, w: 53, h: 13 },
  { name: "58", x: 548, y: 307, w: 100, h: 41 },
  { name: "59", x: 509, y: 451, w: 31, h: 24 },
  { name: "60", x: 501, y: 523, w: 22, h: 5 },
  { name: "61", x: 517, y: 529, w: 32, h: 41 },
  { name: "62", x: 485, y: 425, w: 30, h: 13 },
  { name: "63", x: 525, y: 428, w: 11, h: 6 },
];

layers
  .sort(function (a, b) {
    const areaA = Math.sqrt(a.w * a.w + a.h * a.h);
    const areaB = Math.sqrt(b.w * b.w + b.h * b.h);
    return areaB - areaA;
  })
  .reverse();

// console.table(layers);

export default function Me() {
  const [tick, setTick] = useState(0);
  const [meWidth, setMeWidth] = useState(0);
  const me = useRef(null);

  const handleResize = () => {
    if (me.current) {
      setMeWidth(me.current.offsetWidth);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    const intervalId = setInterval(() => {
      setTick(tick + 1);
    }, 1000 / 60);

    if (tick > layers.length) clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, [tick]);

  return (
    <div className="me" ref={me}>
      <div className="my-portrait" style={{ height: me.current ? meWidth : 0 }}>
        {layers.map((layer, index) => (
          <img
            key={index}
            src={`https://www.r5.ro/2021/me/${layer.name}.png`}
            srcSet={`https://www.r5.ro/2021/me/${layer.name}.png`}
            style={{
              position: "absolute",
              top: `${(layer.y * 100) / 1024}%`,
              left: `${(layer.x * 100) / 1024}%`,
              width: `${(layer.w * 100) / 1024}%`,
              height: `${(layer.h * 100) / 1024}%`,
              opacity: `${index <= tick ? 1 : 0}`,
              filter: `sepia(100%) saturate(250%) brightness(130%) hue-rotate(${
                Math.random() * 10 + 170
              }deg)`,
              background: "white",
              mixBlendMode: "multiply",
            }}
            alt=""
          />
        ))}
      </div>
    </div>
  );
}
