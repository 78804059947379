import React from "react";
import PropTypes from "prop-types";

import { MarginTop, MarginBottom, PaddingTop, PaddingBottom } from "../helpers/spacing";

export default function LayoutElement(props) {
  const propSettings = {
    className: props.className,
    id: props.id,
    span: props.span,
    double: props.double ? "double" : null,
    triple: props.triple ? "triple" : null,
    nowrap: props.nowrap ? "nowrap" : null,
    round: props.round ? "round" : null,
    justify: props.justify ? "justify" : null,
    alignTop: props.alignTop ? "align-top" : null,
    alignCenter: props.alignCenter ? "align-center" : null,

    background: props.bg ? `bg-${props.bg}` : "transparent",
    color: props.color ? `color-${props.color}` : null,
    marginTop: MarginTop(props.marginTop),
    marginBottom: MarginBottom(props.marginBottom),
    paddingTop: PaddingTop(props.paddingTop),
    paddingBottom: PaddingBottom(props.paddingBottom),
  };

  const className = `container ${Object.values(propSettings).join(" ")}`;

  const type = props.type;

  if (type === "section") {
    return <section className={className}>{props.children}</section>;
  }

  return <div className={`${type} ${className}`}>{props.children}</div>;
}

LayoutElement.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  bg: PropTypes.string,
  color: PropTypes.string,
  span: PropTypes.string,
  double: PropTypes.bool,
  triple: PropTypes.bool,
  nowrap: PropTypes.bool,
  round: PropTypes.bool,
  justify: PropTypes.bool,
  alignTop: PropTypes.bool,
  alignCenter: PropTypes.bool,

  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};
