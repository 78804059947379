import React from 'react';
import '../styles/App.min.css';

// https://github.com/duskload/react-device-detect/blob/HEAD/docs/api.md
import { isMobile } from 'react-device-detect';
import { Section, Row, Col, Space } from './Layout/Layout';

import Parallax from './Parallax';
import Paragraph from './Paragraph';
import Heading from './Heading';
import Award from './Award';
import HeadingShadow from './HeadingShadow';
import Year from './Year';
import Image from './Image';
import Quote from './Quote';
import VideoLink from './VideoLink';
import Header from './Header';
import Footer from './Footer';

import Quotes from './data/Quotes.json';

function App() {
  return (
    <div className='App'>
      <Header />

      <main>
        {isMobile ? null : (
          <Section bg='blippar' color='neutral-0'>
            <Row paddingTop='huge'>
              <Col>
                <Year>2012</Year>
              </Col>
            </Row>
          </Section>
        )}

        {/* Blippar */}

        <Section bg='blippar' color='neutral-0'>
          <Row paddingTop='huge'>
            <Col>
              <HeadingShadow text='Blippar'>Blippar</HeadingShadow>
            </Col>
          </Row>

          <Row marginTop='medium'>
            <Col>
              <Paragraph type='huge' marginBottom='medium'>
                One of the UK's first tech unicorns, specialises in Augmented Reality (AR) content
                creation and publishing to any smart phone, tablet and the web.
              </Paragraph>
            </Col>
            <Col double>
              <Paragraph type='huge' marginBottom='medium'>
                It was founded in 2011 and I joined as employee number 16. By the time of its Series
                D round of funding in early 2016, Blippar had been doubling down on its broader
                machine learning and AI efforts, with 60 engineers in its San Francisco and Mountain
                View offices, and 300 employees spread across 14 offices globally, reaching 500 in
                2018.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='large' paddingBottom='huge'>
          <Quote quote={Quotes.Erwan} />
        </Section>

        <Section bg='stylist' color='neutral-0'>
          <Row bg='smoked' paddingTop='huge'>
            <Col marginBottom='medium'>
              <Heading size={3}>Stylist Magazine Olympic Edition</Heading>

              <Paragraph type='big' marginTop='medium'>
                Stylist used the augmented reality app Blippar to present Olympic fever at its
                finest, with their exclusive blippable issue. Thrilling content throughout,
                including videos, polls, live Olympic leader boards and Blipp to Buys make this
                issue something really special.
              </Paragraph>
              <Paragraph type='big' marginTop='small'>
                <strong>My first award winning campaign!</strong>
              </Paragraph>
            </Col>
            <Col double>
              <VideoLink src='stylist' href='https://youtu.be/HXIrDGeCWNo' />
            </Col>
          </Row>
        </Section>

        <Section bg='stylist' color='neutral-0'>
          <Row bg='smoked' paddingTop='medium' paddingBottom='huge'>
            <Col>
              <Award brush={3} logo='effective-mobile-marketing-awards' trophy='0' light>
                GOLD - Effective Mobile Marketing Awards 2012
                <span>Best Mobile Augmented Reality Campaign / Solution</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='blippar' color='neutral-0' paddingTop='large'>
            <Row>
              <Col marginTop='medium'>
                <Year>2013</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='blippar' color='neutral-0' paddingTop='huge' paddingBottom='large'>
          <Row>
            <Col marginTop='medium'>
              <Heading size={3}>Monarch Mountain</Heading>
            </Col>
            <Col double marginTop='medium'>
              <Paragraph type='big'>
                In an aviation industry first WDMP created a direct marketing campaign to promote
                Monarch’s new Ski routes that used Blippar to create a level of intrigue and
                excitement above the norm. The campaign provided an audience of tech-savvy skiers
                with an engaging and unique experience that also delivered useful information,
                giving a strong reason to interact for longer and ultimately click through to book.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0'>
          <Row>
            <Col>
              <VideoLink src='monarch' href='https://www.youtube.com/watch?v=O-SnImwhXE8' />
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='large' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={1} logo='dma-awards' trophy='2' narrow>
                Grand Prix WINNER - The Drum Marketing Awards 2013
                <span>Best Augmented Reality Experience</span>
              </Award>
            </Col>
          </Row>
        </Section>

        <Section bg='juiceburst' color='neutral-0'>
          <Row paddingTop='huge' bg='smoked'>
            <Col marginTop='medium'>
              <Heading size={3}>JuiceBurst</Heading>
              <Heading size={3}>interactive Bottle</Heading>

              <Paragraph type='big' marginTop='medium'>
                Soft drinks brand JuiceBurst has revamped its packaging to incorporate the latest
                augmented reality technology, allowing consumers to use their smart phones to
                interact with product. The Blippar-based technology, according to the brand's owner
                Purity Soft Drinks, is said to be a first in the category.
              </Paragraph>
            </Col>
            <Col double marginTop='small'>
              <VideoLink src='juiceburst' href='https://youtu.be/ET282tAyBFk' />
            </Col>
          </Row>
          <Row bg='smoked' paddingTop='medium' paddingBottom='huge'>
            <Col>
              <Award brush={3} logo='beverige-innovation-awards' trophy='3' light>
                GOLD - Beverage Innovation Awards @ Drinktec 2013
                <span>Best Technology Innovation</span>
              </Award>
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='huge' paddingBottom='large'>
          <Row>
            <Col marginTop='medium' double>
              <Heading size={3}>
                British Heart Foundation and Blippar team up for 'No smoking day'
              </Heading>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                To mark No Smoking Day, the British Heart Foundation has rolled out a campaign with
                Blippar which aims to show smokers how much money they could save by giving up the
                habit, instead of focussing on the health aspect.
              </Paragraph>

              {isMobile ? (
                <Space marginTop='medium'>
                  <VideoLink src='bhf' href='https://youtu.be/g185oKQrQtI' />
                </Space>
              ) : null}
            </Col>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                Those who blipp their packet of cigarettes will see it erupt in a cloud of digital
                smoke before being replaced with 3D representations of all the items the smoker
                could buy with the money saved if they would have quit.
              </Paragraph>
            </Col>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                The project is the first time ever that product packaging has been hijacked using
                augmented-reality for a charity campaign, with the Blippar code working by
                recognising the government health warning on cigarette packs.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='blippar' color='neutral-0'>
            <Row>
              <Col>
                <VideoLink src='bhf' href='https://youtu.be/g185oKQrQtI' />
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='blippar' color='neutral-0' paddingTop='large' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={2} logo='compcomms-digi-awards' trophy='1'>
                GOLD - Corpcomms Digi Awards 2013
                <span>Best Use Of Mobile</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='cover-girl' color='neutral-0'>
            <Row bg='smoked' paddingTop='huge'>
              <Col>
                <Year>2014</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='cover-girl' color='neutral-0'>
          <Row bg='smoked' paddingTop='huge'>
            <Col>
              <Heading size={3}>Covergirl - Hunger Games</Heading>
            </Col>
          </Row>

          <Row bg='smoked' paddingBottom='huge' paddingTop='small'>
            <Col double>
              <Paragraph type='big' marginTop='medium'>
                To build consumer excitement, we teased the campaign in the September issue of
                Vogue. In a first of its kind execution, COVERGIRL created a show stopping high
                impact print unit, and using Blippar technology, a mobile and tablet-enabled
                augmented reality experience, we gave consumers real time access to coveted behind
                the scenes COVERGIRL how-to video content.
              </Paragraph>

              {isMobile ? (
                <Space marginTop='medium'>
                  <VideoLink src='covergirl' href='https://youtu.be/5ebnSQDwzcE' />
                </Space>
              ) : null}

              <Paragraph type='big' marginTop='medium'>
                At the Capitol Beauty Studio a COVERGIRL owned destination, consumers were able to
                interact with each District look, engage with other Hunger Games/COVERGIRL fans and
                even design a look of their own. The same real time, superior augmented reality
                engagement experience was available to consumers in store, all while standing at
                COVERGIRL's “Blippar-ized” displays across 25,000 U.S. stores. Supported with TV,
                digital, social, mobile, OOH and Print, we blanketed the market so COVERGIRL would
                be noticed and win!
              </Paragraph>
            </Col>

            <Col triple marginTop='medium'>
              {isMobile ? null : <VideoLink src='covergirl' href='https://youtu.be/5ebnSQDwzcE' />}

              <Space marginTop='medium'>
                <Award brush={1} logo='iab-mixx-gold' trophy='6' light narrow>
                  GOLD - Iab MIXX 2014
                  <span>Best Cross-Media Integration</span>
                </Award>
              </Space>
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='huge' paddingBottom='huge'>
          <Quote quote={Quotes.Hermes} />
        </Section>

        <Section bg='pepsi-football' color='neutral-0'>
          <Row bg='smoked' paddingTop='huge'>
            <Col>
              <Heading size={3}>Pepsi Max Interactive Football Campaign</Heading>
            </Col>
          </Row>

          <Row bg='smoked' paddingTop='small'>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                Pepsi Max's #FutbolNow campaign - which launched in the UK prior to the 2014 summer
                football season - got 2.4 million interactions from 1.2 million consumers in its
                seven-month run.
              </Paragraph>
              {isMobile ? (
                <Space marginTop='medium'>
                  <VideoLink src='pepsi' href='https://youtu.be/2oBSGEsO5fg' />
                </Space>
              ) : null}
            </Col>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                Fans spent 50,000 hours engaging with Pepsi Max content via the Blippar app - the
                time equivalent of over 35,000 football matches, an impressive figure which was
                calculated by The Drum.
              </Paragraph>
            </Col>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                The campaign turned 242 million Pepsi Max, Pepsi and Diet Pepsi cans and bottles
                into exciting digital experiences which users unlocked by blipping packaging with
                phones and tablets.
              </Paragraph>
            </Col>
          </Row>

          <Row bg='smoked' paddingBottom='huge' paddingTop='medium'>
            <Col triple marginTop='medium'>
              {isMobile ? null : <VideoLink src='pepsi' href='https://youtu.be/2oBSGEsO5fg' />}

              <Space marginTop='medium'>
                <Award brush={2} logo='the-drum-network-awards' trophy='7' light>
                  GOLD - The Drum Network Awards 2014
                  <span>Mobile Marketing Campaign / Strategy of the Year</span>
                </Award>
              </Space>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='blippar' color='neutral-0'>
            <Row paddingTop='huge'>
              <Col>
                <Year>2015</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='blippar' color='neutral-0' paddingTop='huge'>
          <Row>
            <Col marginTop='medium' double>
              <Heading size={3}>Pepsi "Pek Yakında" campaign in Turkey</Heading>
            </Col>
            <Col></Col>
          </Row>
          <Row marginTop='medium'>
            <Col>
              <Paragraph type='big' marginTop='medium'>
                Blippar was the perfect fit, enabling us to pack exciting and exclusive Pek Yakinda
                digital content into Pepsi products, which our fans accessed using the mobile phone
                already sitting in their pocket. Blippar also provided us with powerful real-time
                user data by monitoring exactly how many people were interacting with the Pepsi Pek
                Yakinda blipps, where, when, for how long and how frequently. Tracking engagement
                metrics in this way has enabled us to measure return on investment like never
                before.
              </Paragraph>
            </Col>
            <Col double marginTop='medium'>
              <VideoLink src='pekyakinda' href='https://youtu.be/13MZuDXi560' />
            </Col>
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='huge' paddingBottom='huge'>
          <Quote quote={Quotes.Can} />
        </Section>

        <Section bg='blippar' color='neutral-0'>
          <Row>
            <Col />
            <Col double>
              <Heading size={4}>
                <span className='align-center h4'>
                  This campaign was by far the most successful bringing many awards and nominations,
                  including MWC:
                </span>
              </Heading>
            </Col>
            <Col />
          </Row>
        </Section>

        <Section bg='blippar' color='neutral-0' paddingTop='small' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={1} logo='digital-impact-awards' trophy='8'>
                GOLD - Digital Impact Awards 2015
                <span>Best Use of Digital from the Food &amp; Beverage Sector</span>
              </Award>
            </Col>
          </Row>
          <Row>
            <Col>
              <Award brush={2} logo='iab-mixx-silver' trophy='5'>
                SILVER - iab MIXX Awards Turkey 2014
                <span>Best Augmented Reality</span>
              </Award>
            </Col>
          </Row>
          <Row>
            <Col>
              <Award brush={3} logo='iab-mixx-bronze' trophy='4'>
                BRONZE - iab MIXX Awards Turkey 2014
                <span>Best Mobile Campaign</span>
              </Award>
            </Col>
          </Row>
          <Row>
            <Col>
              <Award brush={4} logo='global-mobile-awards' trophy='9'>
                FINALIST - Mobile World Congress 2015
                <span>Global Mobile Awards - Best Mobile Advertising or Marketing</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='milo' color='neutral-0'>
            <Row bg='smoked' paddingTop='huge' paddingBottom='huge'>
              <Col>
                <Year>2016</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='milo' color='neutral-0'>
          <Row bg='smoked' paddingBottom='huge' paddingTop={isMobile ? 'huge' : null}>
            <Col double marginBottom='small'>
              <Heading size={3}>MILO Champions</Heading>
              <Heading size={4} marginTop='small'>
                Nestlé "Activ Vietnam"
              </Heading>

              <Paragraph type='big' marginTop='medium'>
                Following Nestlé Milo's "For a Powerful and Prosperous Vietnam" initiative, the
                Activ Vietnam programme encourages a dynamic lifestyle, such as regular exercising,
                especially among children of ages 6 to 17.
              </Paragraph>

              {isMobile ? (
                <Space marginTop='medium'>
                  <VideoLink src='milo' href='https://youtu.be/NG9zCjQIk-M' />
                </Space>
              ) : null}

              <Paragraph type='big' marginTop='medium'>
                The programme's goal is to support sport events at localities, donate sports
                equipment to schools, and to help parents encourage their children to be more
                active.
              </Paragraph>

              <Paragraph type='big' marginTop='medium'>
                I created the AR framework for dynamic loading of 2D and 3D assets and bone
                animations then went on location in Vietnam to train the personnel in charge of
                developing the app while creating detailed technical documentation.
              </Paragraph>
            </Col>

            <Col triple marginTop='small'>
              {isMobile ? null : <VideoLink src='milo' href='https://youtu.be/NG9zCjQIk-M' />}

              <Space marginTop='medium'>
                <Award brush={1} logo='apac-effie' trophy='10' light narrow>
                  GOLD - APAC Effie 2018
                  <span>Best Media Campaign</span>
                </Award>
              </Space>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='blippar' color='neutral-0'>
            <Row paddingTop='huge' paddingBottom='large'>
              <Col>
                <Year>2017</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='blippar' color='neutral-0' paddingTop='large' paddingBottom='huge'>
          <Row>
            <Col marginTop='medium' double>
              <Heading size={3}>Blippar's Developer Portal</Heading>
            </Col>
            <Col></Col>
          </Row>
          <Row paddingBottom='huge'>
            <Col double>
              <Paragraph type='h5' marginTop='medium'>
                Meant to bring AR to the world this portal was Blippar's way of opening its
                technology to anyone who wanted to build, develop and code augmented reality
                experiences.
              </Paragraph>

              {isMobile ? (
                <Space paddingTop='medium'>
                  <Image src={'media/projects/blippar-portal.jpg'} alt='Blippar Developer Portal' />
                </Space>
              ) : null}

              <Paragraph type='big' marginTop='medium'>
                I was the main architect of this project being tasked with its overall design,
                HTML/CSS development, creating content and demos. There is an extensive
                documentation that will guide you through AR creation, testing and publishing. The
                Examples section is filled with demos and tutorials that you can try or download, as
                well as a some tips and tricks to guide your through AR development. All my
                expereriecnce ans knowledge as an AR developer went into this alongside the content
                I created in the mentoring / teaching process I created for Blippar's developement
                teams around the world.
              </Paragraph>
            </Col>

            {isMobile ? null : (
              <Col paddingTop='medium'>
                <Image src={'media/projects/blippar-portal.jpg'} alt='Blippar Developer Portal' />
              </Col>
            )}
          </Row>
        </Section>

        {/* MRMC */}

        <Section bg='mrmc-hero' color='neutral-0' paddingBottom='huge' paddingTop='huge'>
          <Row paddingBottom='huge'>
            <Col>
              <HeadingShadow text='Mark Roberts Motion Control'>
                Mark Roberts Motion Control
              </HeadingShadow>
            </Col>
          </Row>
        </Section>

        <Section bg='mrmc' color='neutral-0' paddingTop='large'>
          <Row>
            <Col double>
              <Paragraph type='huge' paddingTop='huge' marginBottom='medium'>
                Delivering Oscar-Winning Engineering for over 50 Years! The leading manufacturer and
                cutting-edge solutions supplier for motion control, automation, broadcast robotics,
                volumetric and remote image capture.
              </Paragraph>
            </Col>
            <Col marginBottom='large'>
              <Image src={'media/queensaward-logo.png'} alt="the queen's awards for enterprise" />
            </Col>
          </Row>

          <Quote quote={Quotes.Assaff} />

          <Row paddingTop='huge'>
            <Col marginBottom='medium'>
              <HeadingShadow text='FLAIR 7'>FLAIR 7</HeadingShadow>
            </Col>
            <Col marginTop='medium' marginBottom='medium'>
              <Paragraph type='big' marginBottom='medium'>
                Flair is MRMC’s premier motion control software, the tool that trully makes your
                vision reality. An award winning control software alongside the revolutionary Bolt X
                robot in 2020.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col marginBottom='medium'>
              <Image
                src={'media/projects/Flair_Square_Transprent_use.png'}
                alt='Flair 7 UI on a monitor'
              />
            </Col>
          </Row>

          <Row>
            <Col double marginBottom='medium'>
              <Paragraph type='big' marginBottom='medium'>
                Designed to give exceptional command over the camera movement, Flair precisely
                controls up to 500 axes of motion, is fully compatible with all of MRMC robots and
                works seamlessly with all CGI packages.{' '}
                <strong>Here's Assaff's Flair 7 controller demo:</strong>
              </Paragraph>
            </Col>

            <Col marginBottom='medium'>
              <button
                className='videoButton'
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=1pUrC9pyY9A', '_blank')
                }>
                <div>
                  <i>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='48'
                      height='48'
                      viewBox='0 0 24 24'>
                      <path d='M3 22v-20l18 10-18 10z' />
                    </svg>
                  </i>
                </div>
              </button>
            </Col>

            <Col double marginBottom='medium'>
              <Paragraph type='big' marginBottom='medium'>
                I was in charge creating the entire line of UX/UI components through an extensive
                requirement gathering that included most of the engineers of the company, creating
                3D models of all the main robots and documenting the on-screen controls.
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col marginBottom='medium'>
              <Award brush={2} logo='nab-show' trophy='11'>
                GOLD - NAB Show 2020 - Product of the Year
                <span>Camera Support, Control and Accessories</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='mrmc' color='neutral-0' paddingTop='huge' paddingBottom='huge'>
            <Row>
              <Col>
                <Year>2018</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='mrmc' color='neutral-0' paddingTop='large' paddingBottom='huge'>
          <Row paddingBottom='medium'>
            <Col marginTop='large' marginBottom='medium'>
              <HeadingShadow text='ORBITAL'>Orbital</HeadingShadow>

              <Paragraph type='big' marginBottom='medium'>
                The Orbital is a powerful robotic camera rig developed to capture high volume
                product shots and video. With programmable, saveable pre-sets, the Orbital brings
                production line efficiency, accuracy and reliability to the world of product
                photography. Create high volume consistent product images and videos at the touch of
                a button.
              </Paragraph>

              {isMobile ? (
                <Space paddingTop='medium' paddingBottom='medium'>
                  <Image src={'media/projects/Orbital.png'} alt='MRMC Orbital robot' />
                </Space>
              ) : null}

              <Paragraph type='big' marginBottom='medium'>
                Assaff Rawner, the managing director praised my efforts on the project being able to
                solve a problem the whole company was trying to tackle for over 25 years -
                pre-emptive collision detection during move planning for Orbital.
              </Paragraph>
            </Col>

            {isMobile ? null : (
              <Col paddingTop='medium'>
                <Image src={'media/projects/Orbital.png'} alt='MRMC Orbital robot' />
              </Col>
            )}
          </Row>

          <Quote quote={Quotes.Rashad} />
        </Section>

        {/* NewDay */}

        <Section bg='aqua-hero' color='neutral-0' paddingBottom='huge' paddingTop='huge'>
          <Row paddingBottom='huge'>
            <Col>
              <HeadingShadow text='NewDay Aquacard'>
                New
                <wbr />
                Day
                <br />
                Aqua
                <wbr />
                card
              </HeadingShadow>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='aqua' paddingTop='huge' paddingBottom='huge'>
            <Row>
              <Col>
                <Year color='aqua'>2019</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='aqua' color='aqua' paddingTop={isMobile ? 'huge' : null} paddingBottom='large'>
          <Row>
            <Col marginTop='small'>
              <Heading size={3}>
                <span className='align-center h3'>
                  <a
                    href='https://www.aquacard.co.uk/'
                    target='_blank'
                    rel='noopener noreferrer nofollow'>
                    Aquacard.co.uk
                  </a>
                </span>
              </Heading>

              <Heading size={4}>
                <span className='align-center h4'>Helping people move forward with credit</span>
              </Heading>
            </Col>
          </Row>

          {isMobile ? (
            <Row paddingBottom='large'>
              <Col marginTop='medium'>
                <VideoLink src='aquacard' href='https://youtu.be/16j_K4DVAl0' />
              </Col>
            </Row>
          ) : null}
        </Section>

        <Section bg='aqua' color='neutral-0'>
          <Quote quote={Quotes.Erin} aqua />
        </Section>

        <Section bg='aqua' color='aqua' paddingTop='huge'>
          <Row paddingBottom='medium'>
            <Col marginBottom='medium'>
              <Paragraph type='big' marginBottom='medium'>
                "We're proud to serve close to 5 million customers across the UK through our diverse
                and growing business. So our purpose is straightforward, to help people move forward
                with credit. Our Customer Manifesto is made up of four core principles, we're a
                welcoming, understanding, knowing and rewarding business. These principles bring the
                Manifesto to life for our customers, colleagues and retail partners."
              </Paragraph>

              {isMobile ? (
                <Space marginBottom='large' marginTop='small'>
                  <button
                    className='acky'
                    onClick={() => window.open('https://www.aquacard.co.uk/', '_blank')}>
                    <Image src={'media/projects/aqua-standing-large.png'} alt='Acky' />
                  </button>
                </Space>
              ) : null}

              <Paragraph type='big' marginBottom='medium'>
                In a team of just two developers we created the award winning public facing website
                for{' '}
                <a
                  href='https://www.aquacard.co.uk/'
                  target='_blank'
                  rel='noopener noreferrer nofollow'>
                  https://www.aquacard.co.uk/
                </a>{' '}
                . It performed amazingly surpassing expected sales by 30%! It is amazingly fast to
                load on any device - far ahead of competitor's websites. The solution uses CMS with
                Umbraco ASP.net framework and passes all WCAG 2.0 accessibility standards.
              </Paragraph>
            </Col>

            {isMobile ? null : (
              <Col marginBottom='medium'>
                <Parallax
                  ranges={{
                    opacity: { values: [0, 1], interval: [0, 0.5] },
                    marginLeft: { values: [40, 0], interval: [0, 0.7], syntax: '?%' },
                    marginRight: { values: [-40, 0], interval: [0, 0.7], syntax: '?%' },
                  }}>
                  <div>
                    <button
                      className='acky'
                      onClick={() => window.open('https://www.aquacard.co.uk/', '_blank')}>
                      <Image src={'media/projects/aqua-standing-large.png'} alt='Acky' />
                    </button>
                  </div>
                </Parallax>
              </Col>
            )}
          </Row>
        </Section>

        <Section bg='aqua' color='neutral-0'>
          <Row>
            <Col marginBottom='large'>
              <Award brush={2} logo='the-uk-digital-experience-awards' trophy='14'>
                GOLD - The UK Digital Experience Awards 2020
                <span>Digital Change &amp; Transformation</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='aqua'>
            <Row paddingBottom='huge' paddingTop='medium'>
              <Col marginTop='medium'>
                <VideoLink src='aquacard' href='https://youtu.be/16j_K4DVAl0' />
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='aqua' color='neutral-0'>
          <Quote quote={Quotes.Anouski} aqua />
        </Section>

        <Section bg='aqua' color='aqua' paddingTop='large'>
          <Row>
            <Col />
            <Col double marginBottom='medium'>
              <Heading size={4}>
                <span className='align-center h4'>
                  The rebranding was also nominated in two categories at The UK Digital Experience
                  Awards in 2020:
                </span>
              </Heading>
            </Col>
            <Col />
          </Row>
        </Section>

        <Section bg='aqua' color='neutral-0' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={3} logo='the-card-payments-awards' trophy='12'>
                FINALIST - Card and Payments Awards 2020
                <span>Best Marketing Campaign of the Year</span>
              </Award>

              <Award brush={1} logo='the-card-payments-awards' trophy='13'>
                FINALIST - Card and Payments Awards 2020
                <span>Best Design of the Year</span>
              </Award>
            </Col>
          </Row>
        </Section>

        {/* Lendingstream */}

        <Section bg='lendingstream-hero' color='neutral-0' paddingBottom='huge' paddingTop='huge'>
          <Row paddingBottom='huge'>
            <Col>
              <HeadingShadow text='Gain Credit Lending Stream'>
                Gain Credit Lending Stream
              </HeadingShadow>
            </Col>
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingTop='large'>
          <Row>
            <Col marginTop='large' marginBottom='medium'>
              <Heading size={3}>A scaled global Fintech focused on responsible credit.</Heading>
            </Col>
          </Row>
          <Row>
            <Col marginTop='small' marginBottom='large'>
              <Paragraph type='big'>
                Lending Stream is a 6-month digital loan provider in the UK. Serving as a viable
                alternative to payday loans since 2008, Lending Stream has been the go-to option for
                people in need of emergency funds; a fact acknowledged by the consistently high
                customer review ratings which it has received since inception. In December 2016,
                Lending Stream won the Alternative Lender of the Year at the F5 Awards.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingBottom='medium'>
          <Quote quote={Quotes.Tushar} />
        </Section>

        {isMobile ? null : (
          <Section bg='lendingstream' color='neutral-0' paddingTop='large' paddingBottom='large'>
            <Row>
              <Col>
                <Year>2020</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='lendingstream' color='neutral-0' paddingTop='large'>
          <Row>
            <Col marginBottom='medium'>
              <Heading size={3} pseudoSize={4}>
                <a
                  href='https://www.lendingstream.co.uk'
                  target='_blank'
                  rel='noopener noreferrer nofollow'>
                  Lendingstream.co.uk
                </a>
              </Heading>
            </Col>
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0'>
          <Row paddingBottom='large'>
            <Col double marginBottom='medium'>
              <Paragraph type='big'>
                The client facing website of Lending Stream was brought to the most modern and
                strict standards proving a very solid platform for content editors to push products
                and updates to the market quickly while positioning itself very high on Google
                rankings. At launch the website was not only fully semantic and conformant to W3C
                standards but got 100% Lighthouse score including SEO and Accessibility (for which
                it passes WCAG 2.1 AA / AAA standards).
              </Paragraph>
            </Col>
            <Col marginBottom='medium'>
              <Paragraph type='big'>
                The CMS and React middleware used make even dynamic pages load in under two seconds,
                while vector graphics and well compressed assets were used making it extremely
                responsive and pleasing as a user experience.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingBottom='medium'>
          <Quote quote={Quotes.Chris} />
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingTop='large'>
          <Row>
            <Col />
            <Col double marginBottom='medium'>
              <Heading size={4}>
                <span className='align-center h4'>
                  This revamp didn't go unnoticed and brought gold for the brand in 2021:
                </span>
              </Heading>
            </Col>
            <Col />
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={3} logo='credit-excellence-awards' trophy='15'>
                GOLD - The Credit Excellence Awards 2021
                <span>Responsible Lender</span>
              </Award>
            </Col>
          </Row>
        </Section>

        <Section bg='lendingstream' color='neutral-0' paddingBottom='huge'>
          <Quote quote={Quotes.Devaraj} />
        </Section>

        {/* MOTORWAY */}

        <Section bg='motorway-hero' color='neutral-0' paddingBottom='huge' paddingTop='huge'>
          <Row paddingBottom='huge'>
            <Col>
              <HeadingShadow text='MOOTRWAY'>
                Motor
                <wbr />
                way
              </HeadingShadow>
            </Col>
          </Row>
        </Section>

        {isMobile ? null : (
          <Section bg='motorway' color='neutral-0' paddingTop='huge'>
            <Row>
              <Col>
                <Year color='neutral-1000'>2021</Year>
              </Col>
            </Row>
          </Section>
        )}

        <Section bg='motorway' color='neutral-1000'>
          <Row paddingTop='huge' paddingBottom='medium'>
            <Col marginBottom='medium'>
              <Paragraph type='h4'>
                As the biggest online-only used car marketplace based in the UK, Motorway uses a
                selling platform to allow users to sell their car directly to dealers. I assisted in
                a short contract for the final push of its award wining platform providing crucial
                accessibility touches alongside key refinements and optimisations.
              </Paragraph>
            </Col>
          </Row>
        </Section>

        <Section bg='motorway' color='neutral-0' paddingBottom='huge'>
          <Row>
            <Col>
              <Award brush={4} logo='motor-trader-industry-awards' trophy='16'>
                GOLD - Motor Trader Industry Awards 2021
                <span>Online Used Car Trading Platform</span>
              </Award>
            </Col>
          </Row>
        </Section>

        <Section bg='motorway' color='neutral-0' paddingBottom='huge'>
          <Row>
            <Quote quote={Quotes.JamesBush} />
          </Row>
        </Section>

        {/* REALM.art */}

        <Section bg='realm-hero' color='neutral-0' paddingBottom='huge' paddingTop='huge'>
          <Row paddingBottom='huge'>
            <Col>
              <HeadingShadow text='REALM.art'>
                REALM
                <wbr />
                .art
              </HeadingShadow>
            </Col>
          </Row>
        </Section>

        <Section bg='realm' color='neutral-0' paddingBottom='huge'>
          <Row paddingTop='huge' paddingBottom='medium'>
            <Col marginBottom='medium'>
              <Paragraph type='h4'>
                Part ot the Metaverse, Realm is a cross chain, community driven open-source project
                that encourages collectors and creators to express their artistic vision in the
                purest form: their own magical virtual realm.
              </Paragraph>
            </Col>
          </Row>

          <Row paddingBottom='large'>
            <Col></Col>
            <Col triple marginBottom='medium'>
              <Paragraph type='big' color='neutral-0'>
                With Realm, you can create unique and customisable virtual ecosystems filled with
                NFTs, own genetically encoded living NFT pets, travel between realms via augmented
                reality (AR), mint collectible NFTs to host exclusive exhibitions, and use live
                audio streaming to connect with friends or fans and much much more. It really feels
                like you're creating your own personal universe.
                <strong> Here's my own unique 3D user NFT:</strong>
              </Paragraph>
            </Col>
            <Col marginBottom='medium'>
              <button
                className='videoButton realm'
                onClick={() => window.open('https://www.r5.ro/realm/egg/', '_blank')}>
                <div>
                  <i>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='48'
                      height='48'
                      viewBox='0 0 24 24'>
                      <path d='M3 22v-20l18 10-18 10z' />
                    </svg>
                  </i>
                </div>
              </button>
            </Col>
            <Col></Col>
          </Row>

          <Row>
            <Quote quote={Quotes.Milan} />
          </Row>
        </Section>
      </main>

      <Footer />
    </div>
  );
}

export default App;
