import React from "react";
// import Parallax from "./Parallax";
// import { isMobile } from "react-device-detect";

export default function Year(props) {
  const str = props.children.split("");

  const content = (
    <h2 className={`heading-year color-${props.color || "neutral-0"}`}>
      {str.map((letter, index) => (
        <span key={index}>{letter}</span>
      ))}
    </h2>
  );

  return content;

  // return isMobile ? (
  //   content
  // ) : (
  //   <Parallax
  //     ranges={{
  //       opacity: { values: [0, 1], interval: [0, 0.5] },
  //     }}>
  //     {content}
  //   </Parallax>
  // );
}
