import { Section, Row, Col, Line, Space } from "./Layout/Layout";
import { isMobile } from "react-device-detect";

import Me from "./Me";
import Contact from "./Contact";
import Heading from "./Heading";

function Header() {
  const intro = (
    <span className="header-quote">
      Here's a brief history of my work in the UK, some of the most successful projects and
      campaigns with the awards they brought - you can even look at all the trophies in 3D - as well
      as quotes about me and my work from the team leads and the product owners.
    </span>
  );

  return (
    <header className="App-header">
      <Section>
        {isMobile ? (
          <Row>
            <Col>
              <Me />
            </Col>

            <Col paddingTop="small" paddingBottom="large">
              <Heading size={1} pseudoSize={2}>
                I'm Radu
              </Heading>

              <Line paddingTop="small">
                <div className="brush-header">
                  <Space paddingBottom="medium" paddingTop="medium">
                    {intro}
                  </Space>
                </div>
              </Line>

              <Contact />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col paddingTop="large" paddingBottom="large">
              <Heading size={1} marginTop="large">
                I'm Radu
              </Heading>

              <Line marginTop="large">
                <div className="brush-header">
                  <Space paddingBottom="medium" paddingTop="medium">
                    {intro}
                  </Space>
                </div>
              </Line>

              <Contact />
            </Col>

            <Col paddingBottom="large">
              <Me />
            </Col>
          </Row>
        )}
      </Section>
    </header>
  );
}

export default Header;
