import React from "react";
// import LazyLoad from "react-lazyload";
import LayoutElement from "./LayoutElement";

const Section = (props) => {
  return (
    // <LazyLoad height={200} offset={100}>
    <LayoutElement type="section" {...props}>
      {props.children}
    </LayoutElement>
    /* </LazyLoad> */
  );
};

const Row = (props) => {
  return (
    <LayoutElement type="row" {...props}>
      {props.children}
    </LayoutElement>
  );
};

const Col = (props) => {
  return (
    <LayoutElement type="col" {...props}>
      <div>{props.children}</div>
    </LayoutElement>
  );
};

const Line = (props) => {
  return (
    <LayoutElement type="line" className={props.spaced ? "spaced" : null} {...props}>
      {props.children}
    </LayoutElement>
  );
};

const Space = (props) => {
  return (
    <LayoutElement type="space" marginTop={props.top} marginBottom={props.bottom} {...props}>
      {props.children}
    </LayoutElement>
  );
};

const Modal = (props) => {
  return (
    <LayoutElement type="modal" marginTop={props.top} marginBottom={props.bottom} {...props}>
      {props.children}
    </LayoutElement>
  );
};

const Click = (props) => {
  return (
    <LayoutElement
      onClick={props.onClick}
      type="click"
      marginTop={props.top}
      marginBottom={props.bottom}
      {...props}>
      {props.children}
    </LayoutElement>
  );
};

const Noclick = (props) => {
  return (
    <LayoutElement type="no-click" marginTop={props.top} marginBottom={props.bottom} {...props}>
      {props.children}
    </LayoutElement>
  );
};

export { Section, Row, Col, Line, Space, Modal, Click, Noclick };
