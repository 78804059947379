import { Line } from './Layout/Layout';

function Contact() {
  return (
    <Line paddingTop='large' paddingBottom='large' alignCenter>
      <button
        className='videoButton header header-cv'
        style={{ zIndex: 1 }}
        onClick={() => window.open('https://www.r5.ro/cv/Radu_Nicolau_CV.pdf', '_blank')}>
        <div>
          <span>CV</span>
        </div>
      </button>

      <button
        className='videoButton header header-phone'
        onClick={() => window.open('tel:+447576969006', '_self')}>
        <div>
          <i>
            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24'>
              <path d='M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z' />
            </svg>
          </i>
        </div>
      </button>

      <button
        className='videoButton header header-email'
        onClick={() => window.open('mailto:radu.alex.nicolau@gmail.com', '_blank')}>
        <div>
          <i>
            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24'>
              <path d='M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z' />
            </svg>
          </i>
        </div>
      </button>

      <button
        className='videoButton header header-linkedin'
        onClick={() => window.open('https://www.linkedin.com/in/radu-nicolau/', '_blank')}>
        <div>
          <i>
            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24'>
              <path d='M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z' />
            </svg>
          </i>
        </div>
      </button>
    </Line>
  );
}

export default Contact;
