import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "./WindowDimensions";

function easeInOut(t) {
  return t > 0.5 ? 4 * Math.pow(t - 1, 3) + 1 : 4 * Math.pow(t, 3);
}

function clamp(n, min, max) {
  return Math.min(Math.max(n, min), max);
}

function val(range, x) {
  const y1 = range.values[1];
  const y2 = range.values[0];
  const style = range.syntax || "?";
  return style.replaceAll("?", y1 + x * (y2 - y1));
}

function Parallax(props) {
  const { height } = useWindowDimensions();

  const [y] = useState(window.scrollY);

  const parallax = useRef();

  const [parallaxRect, setParallaxRect] = useState({ top: 0, bottom: 0 });

  const handleNavigation = (e) => {
    if (parallax && parallax.current) setParallaxRect(parallax.current.getBoundingClientRect());
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  const ParallaxStyle = (ranges, pt) => {
    const style = {};

    Object.entries(ranges).forEach((range) => {
      let i0 = 0;
      let i1 = 1;
      let x = pt;

      if (range[1].interval) {
        i0 = 1 - range[1].interval[1];
        i1 = 1 - range[1].interval[0];

        if (x < i0) {
          x = 0;
        } else if (x > i1) {
          x = 1;
        } else x = (x - i0) / (i1 - i0);
      }

      style[range[0]] = val(range[1], x);
    });

    return style;
  };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    const position = parallaxRect.top / height;
    if (position < -1 || position > 2) {
      return null;
    } else {
      const pt = clamp(easeInOut(position), 0, 1);
      return React.isValidElement(child)
        ? React.cloneElement(child, {
            style: ParallaxStyle(props.ranges, pt),
          })
        : child;
    }
  });

  let code = null;
  switch (props.module) {
    case "span":
      code = <span ref={parallax}>{childrenWithProps}</span>;
      break;
    default:
      code = (
        <div style={{ display: "inline" }} ref={parallax}>
          {childrenWithProps}
        </div>
      );
  }

  return <>{code}</>;
}

export default Parallax;
