import React from "react";
import Image from "./Image";
import { Row, Col, Space } from "./Layout/Layout";
import { isMobile } from "react-device-detect";

export default function Award(props) {
  const brushClass = `brush-${props.brush || 1}${props.light ? "-light" : ""}${
    props.flip ? "-flip" : ""
  }`;

  const logo = (
    <Image src={`media/awards/${props.logo}.png`} alt={`${props.logo.split("-").join(" ")} logo`} />
  );

  const text = (
    <h4 className={`color-${props.light ? "neutral-1000" : "neutral-0"} font-sans`}>
      {props.children}
    </h4>
  );

  const cta = (
    <button
      className="cta award-cta"
      onClick={() => window.open(`https://awards.r5.ro/?trophy=${props.trophy}`, "_blank")}>
      <div>
        <span className="big font-sans">
          {isMobile ? "SEE THE TROPHY" : "VIEW THE TROPHY IN 3D"}
        </span>
      </div>
    </button>
  );

  return isMobile ? (
    <Row bg={`award-mobile ${props.light ? "light" : ""}`} className="award-row">
      <Col>
        <div className="award-mobile">
          {logo}
          {text}
          <Space marginTop="medium" marginBottom="large">
            <div className="align-center">{cta}</div>
          </Space>
        </div>
      </Col>
    </Row>
  ) : (
    <>
      <Row>
        <Col marginTop="medium">
          <div
            className={`award ${props.flip ? "flip" : ""} ${props.light ? "light" : ""} ${
              props.narrow ? "narrow" : ""
            }`}>
            <div className={brushClass}></div>
            <div className="content">
              {logo}
              {text}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="align-center">{cta}</div>
        </Col>
      </Row>
    </>
  );
}
