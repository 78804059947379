import Contact from "./Contact";
import Paragraph from "./Paragraph";

function Footer() {
  return (
    <footer className="App-footer">
      <Contact />
      <Paragraph color="neutral-0" type="small" marginTop="medium">
        © Radu Nicolau 2021. All brands, logos, images and videos are copyright to their respective
        owners.
      </Paragraph>
    </footer>
  );
}

export default Footer;
