import React from "react";
import PropTypes from "prop-types";

import { MarginTop, MarginBottom, PaddingTop, PaddingBottom } from "./helpers/spacing";

export default function Heading(props) {
  const size = props.size;
  const hero = props.hero;
  const color = props.color;
  const pseudo = props.pseudo;
  const pseudoSize = props.pseudoSize;

  const propSettings = {
    marginTop: MarginTop(props.marginTop),
    marginBottom: MarginBottom(props.marginBottom),
    paddingTop: PaddingTop(props.paddingTop),
    paddingBottom: PaddingBottom(props.paddingBottom),
  };

  let classes = [
    hero ? "hero" : null,
    color ? `color-${color}` : "",
    pseudoSize ? ` h${pseudoSize}` : "",
  ];

  const className = classes.join(" ") + " " + Object.values(propSettings).join(" ");

  if (pseudo) {
    return <p className={className + (pseudoSize ? "" : ` h${size}`)}>{props.children}</p>;
  } else {
    switch (size) {
      case 1:
        return <h1 className={className}>{props.children}</h1>;
      case 2:
        return <h2 className={className}>{props.children}</h2>;
      case 3:
        return <h3 className={className}>{props.children}</h3>;
      case 4:
        return <h4 className={className}>{props.children}</h4>;
      case 5:
        return <h5 className={className}>{props.children}</h5>;
      case 6:
        return <h6 className={className}>{props.children}</h6>;
      default:
        return <p>{props.children}</p>;
    }
  }
}

Heading.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  pseudoSize: PropTypes.number,
  hero: PropTypes.bool,
  color: PropTypes.string,
  pseudo: PropTypes.bool,

  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};
