import React from "react";
import { isMobile } from "react-device-detect";

export default function HeadingShadow(props) {
  const heading = props.children || props.text || "???";
  const count = isMobile ? 3 : 5;
  const texts = [];
  const h1 = props.h1;

  for (let n = count; n >= 0; n--) {
    texts.push(
      <span
        key={n}
        className={n > 0 ? null : "solid"}
        style={{
          position: "absolute",
          top: n * 10,
          left: 0,
          opacity: isMobile ? 1 - (n * 0.8) / count : 1 - n / count,
          color: n > 0 ? `rgba(0,0,0,${4 / count})` : null,
          filter: `blur(${n / (0.07 * count)}px)`,
        }}>
        {heading}
      </span>
    );
  }

  const content = (
    <>
      {heading}
      {texts.map((text) => text)}
    </>
  );

  const output = h1 ? (
    <h1 className="heading-shadow">{content}</h1>
  ) : (
    <h3 className="heading-shadow">{content}</h3>
  );
  return output;
}
