import React from 'react';
import PropTypes from 'prop-types';

import { MarginTop, MarginBottom, PaddingTop, PaddingBottom } from './helpers/spacing';

export default function Paragraph(props) {
  const type = props.type;
  const hero = props.hero;
  const color = props.color;
  const strong = props.strong;

  const propSettings = {
    marginTop: MarginTop(props.marginTop),
    marginBottom: MarginBottom(props.marginBottom),
    paddingTop: PaddingTop(props.paddingTop),
    paddingBottom: PaddingBottom(props.paddingBottom),
  };

  let classes = [type, hero ? ' hero' : null, color ? `color-${color}` : ''];

  const className = classes.join(' ') + ' ' + Object.values(propSettings).join(' ');

  return <p className={className}>{strong ? <strong>{props.children}</strong> : props.children}</p>;
}

Paragraph.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  hero: PropTypes.bool,
  color: PropTypes.string,
  strong: PropTypes.bool,

  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};
